import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'


Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '*',
    name: 'not-found',
    redirect: '/not-found'
  },
  {
    path: '/principal',
    name: 'principal',
    component: () => import('@/views/autenticado/Principal.vue'),
    children: [
      {
        path: '/',
        component: () => import('@/views/autenticado/HomeView.vue')
      },
      {
        path: 'home/:chave',
        component: () => import('@/views/autenticado/HomeView.vue')
      },
      {
        path: 'form/',
        component: () => import('@/views/autenticado/FormView.vue')
      },
      {
        path: 'form/:chave',
        component: () => import('@/views/autenticado/FormView.vue')
      },
      {
        path: 'novo-usuario/',
        component: () => import('@/views/autenticado/FormNovoUsuarioView.vue')
      },
      {
        path: 'novo-usuario/:chave',
        component: () => import('@/views/autenticado/FormNovoUsuarioView.vue')
      },
      {
        path: 'clientes/',
        name: 'clientes',
        component: () => import('@/views/autenticado/ClientesView.vue'),
        children: [
          {
            path: ':chave',
            component: () => import('@/components/HomeCliente.vue')
          }  
        ]
      },
      {
        path: 'usuarios/',
        name: 'usuarios',
        component: () => import('@/views/autenticado/UsuariosView.vue'),
        children: [
          {
            path: ':chave',
            component: () => import('@/components/HomeUsuario.vue')
          }  
        ]
      },
      {
        path: 'processos/',
        name: 'processos',
        component: () => import('@/views/autenticado/ProcessosView.vue')
      },
      {
        path: 'assuntos/',
        name: 'assuntos',
        component: () => import('@/components/Assuntos.vue')
      },
      {
        path: 'agenda/',
        name: 'agenda',
        component: () => import('@/views/autenticado/AgendaView.vue')
      },
      {
        path: 'relatorio/',
        name: 'relatorio',
        component: () => import('@/views/autenticado/RelatorioView.vue')
      },
    ]
  },
  {
    path: '/',
    name: 'public',
    component: () => import('@/views/Principal.vue'),
    children: [
      {
        path: 'login/',
        name: 'login',
        component: () => import('@/views/Login.vue')
      },
      {
        path: 'quem-somos/',
        name: 'quem-somos',
        component: () => import('@/views/QuemSomos.vue')
      }
    ]
  },
  {
    path: '/no-access',
    name: 'no-access',
    component: () => import('@/views/NoAccess.vue')
  },
  {
    path: '/not-found',
    name: 'notfound',
    component: () => import('@/views/NotFound.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  const AUTH = localStorage.getItem('hash_auth');
 
  console.log(to.path);
  if (to.path.indexOf('principal') != -1) {

    if(AUTH){
      next();
    } else {
      next("/login");
    }

  } else {
    
    if(AUTH){
      next("/principal");
    } else {
      next();
    }
    
  }
});

export default router
