import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

Vue.config.productionTip = false

import './app.scss';

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
  Vue.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: true,
  position: "top-right"
});

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

try {
  if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
    store.state.dispositivoMovel = true;
   }
} catch (error) {
  
}


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
