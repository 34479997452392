import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    dispositivoMovel: false,
    user: {
      name: 'Eris',
      sobrenome: 'Al',
    },
    usuario: {
      no_nome: '',
      no_email: '',
      no_senha: ''
    },
    auth: false,
    chave_form: '',
    indicacoes: [],
    assuntos: [],
    lista_status_processo: [],
    lista_status: [],
    lista_visoes: [],
    contrato: 
    {
      id_contrato:'',
      nu_assunto: null,
      cpf:'',
      nome:'',
      co_sexo:'',
      indicacao:null,
      dt_contrato:'',
      profissao:'',
      ci:'',
      orgao_Exp:'',
      estado_civil:null,
      quadra:'',
      conjunto:'',
      lote:'',
      bloco:'',
      ap:'',
      cep:'',
      dh_registro:'',
      ic_status_registro:''
    },
    processo: {
      nu_assunto: '',
      numero_processo: '',
      autor: '',
      reu: '',
      assistente: '',
      data_do_protocolo: '',
      valor_da_causa: '',
      vara: '',
      orgao: '',
    },
    processos: [],
    pericias: []
  },
  getters: {
    getLabelAssunto: (state) => (n:number) =>
    {
      const item = state.assuntos.filter((i:any)=>i.value==n)
      return item.length ? item[0]['text'] : '';
    },
    getLabelStatus: (state) => (n:number) =>
    {
      const item = state.lista_status.filter((i:any)=>i.value==n)
      return item.length ? item[0]['text'] : '';
    },
    formataData: (state:any) => (campo:string, _value:string,_text:string, data:any) => {
      const res = [{ value: null, text: "Selecione" }];
      data.filter((i:any) => {
        res.push({
          value: i[_value],
          text: i[_text],
        });
      });
      state[`${campo}`] = res;
    },
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
